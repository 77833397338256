 const isTelAvailable = function(tel) {
     var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
     if (!myreg.test(tel)) {
         return false;
     } else {
         return true;
     }
 }
 const isEmail = function(tel) {
  var myreg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
  if (!myreg.test(tel)) {
      return false;
  } else {
      return true;
  }
}
 export { isTelAvailable,isEmail }
