import { render, staticRenderFns } from "./price.vue?vue&type=template&id=f8936cd0&scoped=true&"
import script from "./price.vue?vue&type=script&lang=js&"
export * from "./price.vue?vue&type=script&lang=js&"
import style0 from "./price.vue?vue&type=style&index=0&id=f8936cd0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8936cd0",
  null
  
)

export default component.exports