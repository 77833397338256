<template>
  <div class="hello">
    <div class="one" v-for="item in priceArr" :key="item.id" :class="item.id==id?'bgblue':''" @click="choiceMenu(item)" >
      <div class="time">{{item.name}}</div>
      <div class="price">￥{{item.price}}</div>
      <div class="discount" v-if="item.discount">{{item.discount}}折</div>
      <div class="oldPrice" v-if="item.discount">￥{{item.original_price}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'price',
  props: {
    priceArr: Array,
  },
  data(){
    return {
      id:-1
    }
  },
  methods:{
    choiceMenu(item){
      this.id = item.id

      this.$emit('menu',item)
      console.log(item)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hello {
  display: flex;
  justify-content: space-between;
}
.one {
  position: relative;
  width: 217px;
  border-radius: 10px;
  border: 1px solid #eee;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  height: 130px;
  .time{
    font-size: 28px;
    margin-left: 25px;
    line-height: 30px;
    margin-top: 20px;
  }
  .price{
    color: red;
    font-size: 28px;
    line-height: 30px;
    margin-left: 20px;
  }
  .discount {
    background-color: #f6c143;
    position: absolute;
    top: -9px;
    left: 130px;
    line-height: 18px;
    font-weight: 700;
    border-radius: 5px;
    color: #ffffff;
    width: 60px;
    height: 26px;
    line-height: 26px;
    text-align: center;
  }
  .oldPrice{
    font-size: 16px;
    color: #858789;
    text-decoration: line-through;
    line-height: 20px;
    margin-left: 24px;
  }
}
.bgblue{
  background-color: #eef5fe;
}
</style>
